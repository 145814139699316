<template>
    <el-container>
        <el-header height="45px" v-if="checkRight('show_header')">
            <el-steps :active="type" simple>
                <el-step :icon="type == 0 ? 'el-icon-edit' : 'el-icon-success'" @click.native="type = 0">
                    <template slot="title">
                        <el-tooltip class="item" effect="dark" content="点击切换" placement="top-start">
                            <span>计划{{ config.field1Text }}</span>
                        </el-tooltip>
                    </template>
                </el-step>
                <el-step :icon="type == 1 ? 'el-icon-edit' : ''" @click.native="type = 1">
                    <template slot="title">
                        <el-tooltip class="item" effect="dark" content="点击切换" placement="top-start">
                            <span>商品{{ config.field2Text }}</span>
                        </el-tooltip>
                    </template>
                </el-step>
            </el-steps>
        </el-header>
        <el-main>
            <el-table ref="detailTable" :span-method="objectSpanMethod" :data="list" v-loading="loading" border
                height="100%" class="detail_table"
                :header-cell-style="{ 'background-color': '#E5EEF6', 'border-color': 'rgba(218, 223, 228, 1)', height: '48px' }">
                <el-table-column prop="ItemNo" label="货号" header-align="center" align="center" width="180"
                    :resizable="false" :filters="itemNoFilters" :filter-method="handleFilter">
                    <template slot-scope="{ row }">
                        <div class="pointed">{{ row.ItemNo }}</div>
                        <div>
                            <span>共计<span class="num">{{ getTotal(config.field1Name, row.ItemNo) }}</span></span>
                            <span>{{ config.field2Text }}<span class="num">{{ getTotal(config.field2Name, row.ItemNo)
                            }}</span></span>件
                        </div>
                        <div v-if="checkRight('show_assign_edit', row)" @click="handleSetEmptySpec(row)"
                            class="div-wait-setspec" title="点击去分配">
                            待分配 {{ calcEmptySpec(row.ProductID) }} 件
                        </div>
                        <div v-if="checkRight('show_assign_complete', row)" class="div-setspeced">
                            待{{ config.field2Text }} {{ getEmptySpecTotal(row.ProductID) }} 件
                        </div>
                    </template>
                </el-table-column>
                <el-table-column prop="Color" label="颜色" header-align="center" align="center" width="180" :resizable="false"
                    :filters="colorFilters" :filter-method="handleFilter">
                    <template slot-scope="{ row }">
                        <div class="pointed">{{ row.Color }}</div>
                        <div>
                            <span>共计<span class="num">{{ getTotal(config.field1Name, row.ItemNo, row.Color) }}</span></span>
                            <span>{{ config.field2Text }}<span class="num">{{ getTotal(config.field2Name, row.ItemNo,
                                row.Color)
                            }}</span></span>件
                        </div>
                    </template>
                </el-table-column>
                <el-table-column prop="Size" label="尺码" header-align="center" align="center" width="180" :resizable="false">
                    <template slot-scope="{ row }">
                        <div class="pointed">{{ row.Size }}</div>
                        <div v-if="checkRight('show_header')">
                            <span>{{ config.field1Text }}<span class="num">{{ row[config.field1Name] }}</span></span>
                            <span>{{ config.field2Text }}<span class="num">{{ row[config.field2Name] }}</span></span>件
                        </div>
                    </template>
                </el-table-column>
                <el-table-column :prop="config.field1Name" :label="config.field1Text + '数量'" header-align="center"
                    align="center" :resizable="false" v-if="checkRight('show_column1')">
                    <template slot="header" v-if="field1HasEdit">
                        <span style="line-height: 32px;">{{ config.field1Text }}数量</span>
                        <el-popover placement="top-start" trigger="click" :title="'批量设置' + config.field1Text + '数量'"
                            class="table_btn_right">
                            <el-input-number style="width: 150px" :controls="false" :min="0" v-model="batchSetNum1"
                                @focus="handleSelectText($event)" @change="handleBatchNum(1)"></el-input-number>
                            <el-button type="primary" slot="reference" @click="handleShowBatchSet">批量设置</el-button>
                        </el-popover>
                    </template>
                    <template slot-scope="{ row, $index }">
                        <template v-if="checkRight('column1_is_edit', row, $index)">
                            <el-input-number :controls="false" :min="row['Origin' + config.field1Name]" style="width:100%;"
                                class="edit_input" v-model="row[config.field1Name]" @focus="handleSelectText($event)"
                                @change="changeStatistics(row)"></el-input-number>
                        </template>
                        <template v-else>
                            <span class="pointed">{{ row[config.field1Name] }}</span>
                        </template>
                    </template>
                </el-table-column>
                <el-table-column :prop="config.field2Name" :label="config.field2Text + '数量'" header-align="center"
                    align="center" :resizable="false" v-if="checkRight('show_column2')">
                    <template slot="header" v-if="field2HasEdit">
                        <span style="line-height: 32px;">{{ config.field2Text }}数量</span>
                        <el-button @click="handleAllIn" type="primary" style="float:left;" class="table_btn_left"
                            size="small">全部{{ config.field2Text
                            }}</el-button>
                        <el-popover placement="top-start" trigger="click" :title="'批量设置' + config.field2Text + '数量'"
                            class="table_btn_right">
                            <el-input-number style="width: 150px" :controls="false" :min="0" v-model="batchSetNum2"
                                @focus="handleSelectText($event)" @change="handleBatchNum(2)"></el-input-number>
                            <el-button type="primary" slot="reference" size="small"
                                @click="handleShowBatchSet">批量设置</el-button>
                        </el-popover>
                    </template>
                    <template slot-scope="{ row, $index }">
                        <template
                            v-if="checkRight('column2_is_edit', row, $index)">
                            <el-input-number :controls="false" :min="row['Origin' + config.field2Name]" class="edit_input"
                                :style="typeof (row['Stock']) != 'undefined' ? 'width:70%;' : 'width:100%;'"
                                v-model="row[config.field2Name]" @focus="handleSelectText($event)"
                                @change="changeStatistics(row)"></el-input-number>
                            <span class="span_stock">{{ typeof (row['Stock']) != 'undefined' ? ('(剩' + row['Stock'] + ')') :
                                '' }}</span>
                        </template>
                        <template v-else>
                            <span class="pointed">{{ row[config.field2Name] }}</span>
                        </template>
                    </template>
                </el-table-column>
                <el-table-column v-for="(item, idx) in config.otherColumns" :key="'other_column_' + idx" :prop="item.prop"
                    :label="item.label" header-align="center" align="center" :width="item.width ? item.width : ''"
                    :resizable="false">
                    <template slot-scope="{ row }">
                        {{ row[item.prop] }}
                    </template>
                </el-table-column>
                <el-table-column prop="OP" label="操作" header-align="center" align="center" width="80" :resizable="false"
                    v-if="isEditNum && showDeleteRow">
                    <template slot-scope="{ row, $index }">
                        <el-button @click="handleDeleteRow(row, $index)" type="primary"
                            :disabled="row['_NotDelete'] == 'Y'">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
        </el-main>
        <add-goods ref="addGoodsDlg" @handleSave="handleAddGoods"></add-goods>
    </el-container>
</template>

<script>
import { submit } from "@/api/base";
import AddGoods from "@/views/purchase/components/AddGoods.vue";
export default {
    name: "DetailsTable",
    components: { AddGoods },
    props: {
        config: {
            field1Name: '',
            field1Text: '',
            field1EditConditions: [],// ['字段','条件如：>=', 0] ，[其他] ]
            field2Name: '',
            field2Text: '',
            field2EditConditions: [],
            otherColumns: [],
        },
        mode: null, //step、list
        showDeleteRow: {
            type: Boolean,
            default: false,
        },
        showAssignProduct: {//当规格ID为0时可以分配商品
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            loading: false,
            itemNoFilters: [],
            colorFilters: [],
            type: 0,//0计划，1结果
            list: [],
            main: null,
            isEditNum: false,
            isComplete: false,//单据是否已完结，
            mergeObj: {}, // 用来记录需要合并行的下标
            mergeArr: ["ItemNo", "Color"], // 表格中的列名
            batchSetNum1: undefined,
            batchSetNum2: undefined,
            field1HasEdit: false,
            field2HasEdit: false,
        };
    },
    methods: {
        checkRight(type, row, idx) {
            var that = this;
            switch (type) {
                case "show_header":
                    return that.mode == 'step' && !that.isComplete;//step模式且状态为未完成可编辑状态
                case "show_assign_edit":
                    return that.showAssignProduct && that.calcEmptySpec(row.ProductID) > 0;
                case "show_assign_complete":
                    return that.showAssignProduct && that.calcEmptySpec(row.ProductID) <= 0 && that.main.EmptySpecs[row.ProductID].EmptyCount > 0;
                case "show_column1":
                    return (that.mode == 'step' && that.type == 0) || that.mode == 'list' || that.isComplete;
                case "column1_is_edit":
                    return that.isEditNum && that.checkEdit(1, row, idx) && (!that.showAssignProduct || (that.showAssignProduct && row['SpecID'] > 0));
                case "show_column2":
                    return (that.mode == 'step' && that.type == 1) || that.mode == 'list' || that.isComplete;
                case "column2_is_edit":
                    return that.isEditNum && that.checkEdit(2, row, idx) && (!that.showAssignProduct || (that.showAssignProduct && row['SpecID'] > 0));
                default:
                    return false;
            }
        },
        handleShowBatchSet() {
            this.batchSetNum1 = undefined;
            this.batchSetNum2 = undefined;
        },
        handleBatchNum(flag) {
            var that = this;
            var num = 0;
            var field = '';
            switch (flag) {
                case 1:
                    num = that.batchSetNum1;
                    field = that.config.field1Name;
                    break;
                case 2:
                    num = that.batchSetNum2;
                    field = that.config.field2Name;
                    break;
            }
            if (typeof (num) != 'undefined' && num != '' && num > 0) {
                that.batchSetNum(num, field);
            }
        },
        handleAddGoods(data) {
            var that = this;
            var emptySpecCount = that.calcEmptySpec(data.ProductID);
            if (emptySpecCount <= 0) {
                that.$baseMessage('货号' + data.ItemNo + '没有待分配的商品', "error");
                return;
            }
            for (let color in data.Colors) {
                for (let i = 0; i < data.Colors[color].Details.length; i++) {
                    var hasSpec = that.list.some(x => x.ProductID == data.ProductID && x.SpecID == data.Colors[color].Details[i].SpecID);
                    if (!hasSpec) {
                        that.list.push({
                            ProductID: data.Colors[color].Details[i].ProductID,
                            ItemNo: data.ItemNo,
                            SpecID: data.Colors[color].Details[i].SpecID,
                            SpecName: color + '-' + data.Colors[color].Details[i].Size,
                            Color: color,
                            Size: data.Colors[color].Details[i].Size,
                            Num: 0,
                            CheckNum: 0,
                            CheckStatus: 0,
                        });
                    }
                }
            }
            that.list = that.list.filter(x => x.ProductID != data.ProductID || (x.ProductID == data.ProductID && x.Color != ''))
            //排序
            that.getSpanArr();
        },
        handleSetEmptySpec(row) {
            this.$refs.addGoodsDlg.init(false, '分配', row.ItemNo);
        },
        getEmptySpecTotal(productId) {
            var that = this;
            if (typeof (that.main.EmptySpecs[productId]) != 'undefined') {
                return that.main.EmptySpecs[productId].EmptyCount + that.main.EmptySpecs[productId].OtherCount;
            }
            return 0;
        },
        calcEmptySpec(productId) {
            var that = this;
            var count = 0;
            var total = that.getEmptySpecTotal(productId);
            that.list.forEach(i => {
                if (i.ProductID == productId) {
                    if (i.SpecID <= 0) {
                        count += i.CheckNum;
                    } else {
                        if (i.Num > 0) {
                            if (i.CheckNum >= i.Num) {
                                count += i.CheckNum;
                            } else {
                                count += i.Num;
                            }
                        } else {
                            count += i.CheckNum;
                        }
                    }
                }
            });
            return total - count;
        },
        checkEdit(flag, row, idx) {
            var conditions = [];
            var data = [];
            switch (flag) {
                case 1:
                    data = this.config.field1EditConditions;
                    break;
                case 2:
                    data = this.config.field2EditConditions;
                    break;
            }
            if (typeof (data) != 'undefined' && Array.isArray(data) && data.length > 0) {
                data.forEach(item => {
                    if (Array.isArray(item) && item.length == 3) {
                        let txt = item[0];
                        if (txt.indexOf('$main$') >= 0 && typeof (this.main) != 'undefined') {
                            txt = txt.replace('$main$', 'this.main');
                        }
                        if (txt.indexOf('$row$') >= 0) {
                            txt = txt.replace('$row$', 'this.list[' + idx + ']');
                        }
                        conditions.push(txt + item[1] + item[2]);
                    }
                });
            }
            if (conditions.length > 0) {
                return eval(conditions.join('&&'));
            }
            return true;
        },
        setData(data, isEditNum, isComplete, notDelete, main) {
            var that = this;
            that.main = main;
            that.$refs.detailTable.clearFilter();
            that.list = data;
            if (typeof (isEditNum) != 'undefined') {
                that.isEditNum = isEditNum;
            }
            if (typeof (isComplete) != 'undefined') {
                that.isComplete = isComplete;
            }
            that.type = 0;
            that.getSpanArr();
            that.statisticsNumAndCallback(false);
            //统计
            that.field1HasEdit = false;
            that.field2HasEdit = false;
            for (let i = 0; i < that.list.length; i++) {
                let x = that.list[i];
                //禁用删除
                if (typeof (notDelete) != 'undefined' && notDelete) {
                    x['_NotDelete'] = 'Y';
                }
                //统计未分配数量
                if (that.showAssignProduct) {
                    if (typeof (that.main.EmptySpecs) == 'undefined') {
                        that.main.EmptySpecs = {};
                    }
                    let emptyCount = x.SpecID == 0 ? x.Num : 0;
                    let otherCount = x.SpecID != 0 ? x.Num : 0;
                    if (typeof (that.main.EmptySpecs[x.ProductID]) == 'undefined') {
                        that.main.EmptySpecs[x.ProductID] = {
                            EmptyCount: emptyCount,
                            OtherCount: otherCount,
                        };
                    } else {
                        that.main.EmptySpecs[x.ProductID].EmptyCount += emptyCount;
                        that.main.EmptySpecs[x.ProductID].OtherCount += otherCount;
                    }
                }
                //批量设置按钮
                let flag1 = isEditNum && that.checkEdit(1, x, i);
                if (flag1) {
                    that.field1HasEdit = true;
                }
                let flag2 = isEditNum && that.checkEdit(2, x, i);
                if (flag2) {
                    that.field2HasEdit = true;
                }
            }
        },
        handleAllIn() {
            var that = this;
            for (let i = 0; i < that.list.length; i++) {
                if (that.list[i]['SpecID'] > 0) {
                    that.list[i][that.config.field2Name] = that.list[i][that.config.field1Name];
                }
            }
        },
        handleDeleteRow(row, idx) {
            var that = this;
            if (that.list.length > 0) {
                that.list.splice(idx, 1);
                that.getSpanArr();
                that.statisticsNumAndCallback(true);
            }
        },
        batchSetNum(num, field) {
            var that = this;
            for (let i = 0; i < that.list.length; i++) {
                if (that.showAssignProduct && that.list[i]['SpecID'] <= 0) {
                    continue;
                }
                if (typeof (field) == 'undefined') {//自动
                    if (that.mode == 'step') {
                        if (that.type == 0) {
                            that.list[i][that.config.field1Name] = num > that.list[i]['Origin' + that.config.field1Name] ? num : that.list[i]['Origin' + that.config.field1Name];
                        }
                        if (that.type == 1) {
                            that.list[i][that.config.field2Name] = num;
                        }
                    }
                }
                if (typeof ('field') != 'undefined' || that.mode == 'list') {
                    that.list[i][field] = num > that.list[i]['Origin' + field] ? num : that.list[i]['Origin' + field];
                }
            }
        },
        changeStatistics(row) {
            var that = this;
            let isUpdate = false;
            if (row[that.config.field1Name] != row['Old' + that.config.field1Name]) {
                row['Old' + that.config.field1Name] = row[that.config.field1Name];
                isUpdate = true;
            }
            if (row[that.config.field2Name] != row['Old' + that.config.field2Name]) {
                row['Old' + that.config.field2Name] = row[that.config.field2Name];
                isUpdate = true;
            }
            if (!isUpdate) {
                return;
            }
            that.statisticsNumAndCallback(isUpdate);
        },
        statisticsNumAndCallback(isUpdate) {
            var changNum1 = 0;
            var changNum2 = 0;
            var itemNos = {};
            var that = this;
            if (that.$listeners['handleChanged']) {
                for (let i = 0; i < that.list.length; i++) {
                    changNum1 += that.list[i][that.config.field1Name];
                    changNum2 += that.list[i][that.config.field2Name];
                    itemNos[that.list[i]['ItemNo']] = 1;
                }
                let itemNoCount = Object.keys(itemNos).length;
                that.$emit("handleChanged", changNum1, changNum2, itemNoCount, isUpdate);
            }
        },
        handleSelectText(e) {
            e.currentTarget.select();
        },
        handleFilter(value, row, column) {
            const property = column['property'];
            return row[property] === value;
        },
        getTotal(field, itemNo, color) {
            var that = this;
            let count = 0;
            for (let i = 0; i < that.list.length; i++) {
                if (typeof (color) == 'undefined') {
                    if (that.list[i].ItemNo == itemNo) {
                        count += that.list[i][field];
                    }
                } else {
                    if (that.list[i].ItemNo == itemNo && that.list[i].Color == color) {
                        count += that.list[i][field];
                    }
                }
            }
            return count;
        },
        sortList() {
            var that = this;
            that.list = that.list.sort(function (a, b) {
                if (a.ProductID !== b.ProductID) return a.ProductID < b.ProductID ? -1 : 1
                else if (a.Color !== b.Color) return a.Color < b.Color ? -1 : 1
                else if (a.Size !== b.Size) return a.Size < b.Size ? -1 : 1
            });
            var itemNos = {};
            var colors = {};
            for (let i = 0; i < that.list.length; i++) {
                var item = that.list[i];
                itemNos[item.ItemNo] = 1;
                colors[item.Color] = 1;
                item['Origin' + that.config.field1Name] = item[that.config.field1Name];
                item['Old' + that.config.field1Name] = item[that.config.field1Name];
                item['Origin' + that.config.field2Name] = item[that.config.field2Name];
                item['Old' + that.config.field2Name] = item[that.config.field2Name];
            }
            that.itemNoFilters = [];
            for (let key in itemNos) {
                that.itemNoFilters.push({ text: key, value: key });
            }
            that.colorFilters = [];
            for (let key in colors) {
                that.colorFilters.push({ text: key, value: key });
            }
        },
        objectSpanMethod({ row, column, rowIndex, columnIndex }) {
            if (this.mergeArr.indexOf(column.property) !== -1) {
                if (row[column.property] == '') {
                    return [1, 1];
                }
                if (this.mergeObj[column.property][rowIndex]) {
                    return [this.mergeObj[column.property][rowIndex], 1];
                } else {
                    return [0, 0];
                }
            }
        },
        getSpanArr() {
            let that = this;
            that.sortList();
            this.mergeArr.forEach(key => {
                let itemIndex = 0;
                that.mergeObj[key] = [];
                that.list.forEach((item, index) => {
                    if (index === 0 || (index > 0 && item[key] != that.list[index - 1][key])) {
                        itemIndex = index;
                        that.mergeObj[key].push(1);
                    } else {
                        that.mergeObj[key][itemIndex] += 1;
                        that.mergeObj[key].push(0);
                    }
                });
            });
        },

    }
}
</script>
<style lang="scss" scoped>
::v-deep {
    .table_btn_right {
        position: absolute;
        right: 3px;
    }

    .table_btn_left {
        position: absolute;
        left: 3px;
    }

    .table_btn_left,
    .table_btn_right .el-button--small {
        padding: 5px 5px !important;
        margin-top: 4px;
    }

    .div-setspeced {
        color: green;
        font-size: 14px;
    }

    .div-wait-setspec:hover {
        text-decoration: underline;
    }

    .div-wait-setspec {
        color: red;
        font-size: 14px;
        cursor: pointer;
    }

    .span_stock {
        color: red;
    }

    .edit_input .el-input__inner {
        height: 50px !important;
        font-size: 40px !important;
    }

    .pointed {
        color: #409EFF !important;
        margin: 0 5px;
        font-size: 16px;
    }

    .num {
        margin: 0 2px;
        font-size: 16px;
        font-weight: bold;
    }
}
</style>